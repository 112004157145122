<template>
    <div class="relative w-full col-flex">
        <div class="w-full h-full absolute inset-0 z-1">
            <v-image
                :option_key="`${route.name}_banner`"
                :width="2000"
                alt="Banner"
                class="w-full h-full object-cover"
            />
        </div>
        <div class="absolute inset-0 z-2 bg-overlay-01"></div>
        <div class="container relative z-10 py-20 lg:py-37">
            <div class="w-full text-left">
                <GlobalBreadcrumb v-if="breadcrumb && breadcrumb.length > 0" :breadcrumb="breadcrumb" />
                <div v-if="!is_edit">
                    <div
                        v-html="convertedTextHeading"
                        :ref="myVariable[js_heading]"
                        :class="'heading_' + route.name"
                        class="text-white"
                    ></div>
                    <!-- <div
                        :ref="myVariable[js_sub_heading]"
                        v-html="convertedTextSub"
                        :class="'sub_heading_' + route.name"
                        class="text-base lg:text-lg text-white mt-2 max-w-262"
                    ></div> -->
                </div>

                <div v-else>
                    <v-content :option_key="`${route.name}_heading`" class="text-white"> </v-content>

                    <!-- <v-content
                        :option_key="`${route.name}_subHeading`"
                        class="lg:text-lg text-white font-medium mt-2 max-w-262"
                    >
                    </v-content> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
defineProps({
    breadcrumb: {
        type: Array as any
    }
})
const route = useRoute()
let myVariable = {}
const js_heading = ref(`js_heading_${route.name}`)
// const js_sub_heading = ref(`js_sub_heading_${route.name}`)

myVariable = {
    [js_heading.value]: ref<HTMLElement>([])
    // [js_sub_heading.value]: ref<HTMLElement>([])
}
const headingDefault = ref(getDataGlobalByKey('vi-VN', `CONTENT__${route.name}_heading`)?.option_value)

// const subHeadingDefault = ref(getDataGlobalByKey('vi-VN', `CONTENT__${route.name}_subHeading`)?.option_value)
const convertedTextHeading = computed(() => headingDefault.value)
// const convertedTextSub = computed(() => convertNewlinesToBr(subHeadingDefault.value))
const is_edit = inject('is_edit')
onMounted(() => {
    if (!is_edit) {
        const wordsHeading = convertedTextHeading.value
        myVariable[js_heading.value].value.innerHTML = ''
        // const wordsSubHeading = myVariable[js_sub_heading.value].value.innerText.split(' ')
        // myVariable[js_heading.value].value.innerText = ''
        // myVariable[js_sub_heading.value].value.innerText = ''

        document.querySelectorAll(`.heading_${route.name}`).forEach((item: any) => item.classList.add('active'))
        // document.querySelectorAll(`.sub_heading_${route.name}`).forEach((item: any) => item.classList.add('active'))
        // wordsHeading.forEach(function (word) {
        //     const span = document.createElement('span')
        //     span.innerText = word + ' '
        //     myVariable[js_heading.value].value.appendChild(span)
        //     document.querySelectorAll(`.heading_${route.name} span`).forEach(function (span, index) {
        //         span.style.animation = `fade-in-heading 0.8s ${0.1 * index}s forwards cubic-bezier(0.11, 0, 0.5, 0)`
        //     })
        // })
        wordsHeading.split(/(<\>)/).forEach(function (word) {
            const div = document.createElement('div')
            // console.log('🚀 ~ file: SectionBanner.vue:91 ~ div:', div)
            div.innerHTML = word + ' '
            myVariable[js_heading.value].value.appendChild(div)
            myVariable[js_heading.value].value.querySelectorAll('div').forEach(function (div, index) {
                div.querySelectorAll('p , li').forEach(function (content, index) {
                    content.textContent.split(' ').forEach((word: string) => {
                        const span = document.createElement('span')
                        span.innerText = word + ' '
                    })
                    // content.parentNode.replaceChild(blockAnimation, content)
                })
                div.classList.add('inline', 'leading-25px', 'sub-heading-collection-custom')

                div.style.animation = `fade-in-heading 1s ${0.1 * index}s forwards cubic-bezier(0.11, 0, 0.5, 0)`
            })
        })
        // wordsSubHeading.forEach(function (word) {
        //     const span = document.createElement('span')
        //     span.innerText = word + ' '
        //     myVariable[js_sub_heading.value].value.appendChild(span)
        //     document.querySelectorAll(`.sub_heading_${route.name} span`).forEach(function (span, index) {
        //         span.style.animation = `fade-in-heading 2s ${0.1 * index}s forwards cubic-bezier(0.11, 0, 0.5, 0)`
        //     })
        // })
    }
})
</script>

<style></style>
